@import "stylesheets/antd.css";
@import "./colors";

// @import url("https://fonts.googleapis.com/css?family=Lato");

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

body {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  background: $background-color;
}

* {
  box-sizing: border-box;
}

.center {
  text-align: center;
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}

.bottom-right-fixed {
  position: fixed;
  bottom: 32px;
  right: 32px;
}

.bottom-left-fixed {
  position: fixed;
  bottom: 32px;
  left: 32px;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex !important;
}
.d-flex {
  flex-wrap: wrap !important;
}
.justify-content-start {
  justify-content: start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-space-around {
  justify-content: space-around;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-self-center {
  align-self: center !important;
}

.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}

.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mt-1 {
  margin-top: 4px;
}
.mt-2 {
  margin-top: 8px;
}
.mt-3 {
  margin-top: 12px;
}
.mt-4 {
  margin-top: 16px;
}
.mt-5 {
  margin-top: 20px;
}

.mb-1 {
  margin-bottom: 4px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-5 {
  margin-bottom: 20px;
}

.ml-1 {
  margin-left: 4px;
}
.ml-2 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-4 {
  margin-left: 16px;
}
.ml-5 {
  margin-left: 20px;
}

.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mr-4 {
  margin-right: 16px;
}
.mr-5 {
  margin-right: 20px;
}
