@import "./../../styles/colors.scss";

:root {
  /* Make the mention background blue. */
  --ck-color-mention-background: #e6f7ff;

  /* Make the mention text dark grey. */
  --ck-color-mention-text: #1890ff;

  --ck-color-list-button-on-background: #83c8bc;
}

.page-interpretation {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 32px 32px;

  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
    outline: none;
    border: 1px solid $green;
    box-shadow: none;
  }
  .interpreting-editor {
    .ck.ck-sticky-panel__content {
      display: none;
    }
  }
  .interpreting-editor.focused-editor {
    .ck.ck-sticky-panel__content {
      display: block;
    }
  }
}

.see-preview-container {
  color: $green;
  cursor: pointer;
}

.templating-section-close-icon {
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 99;
  background-color: $green;
  padding: 8px 12px;
  color: $white;
}
.create-new-template-select-option {
  color: $green;
  font-size: 14px;
  line-height: 22px;
  border-top: 1px solid #f0f0f0;
}

.drawer {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  height: 100vh;
  .snippet-list-section {
    height: 90%;
    overflow-y: scroll;
    .header {
      .components-container {
        margin: 16px 24px;
        .text_title {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #262626;
        }
      }
    }
    .divider {
      margin: 12px 0px;
    }
    .body {
      margin: 16px 24px;
      .list-container {
        .list-header {
          margin-bottom: 8px;
          .text_title {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
          }
          .extra {
            color: $gray;
            .icon {
              &:hover {
                opacity: 0.5;
                cursor: pointer;
              }
            }
          }
        }
        .list-body {
          cursor: pointer;
          .list {
            padding: 8px 12px;
            border: 1px solid $gray-lighter;
          }
        }
      }
    }
    .snippet-btn-section {
      height: 20%;
      float: right;
    }
  }
}

// add inside parent class
.template-list-option .extra {
  visibility: hidden;
  color: $gray;
  float: right;
  padding-right: 12px;
  .icon {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}
.template-list-option:hover .extra {
  visibility: visible;
  color: $gray;
  float: right;
  padding-right: 12px;
  .icon {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}

.ant-select-selection-item {
  .extra {
    color: $gray;
    float: right !important;
    padding-right: 12px !important;
    .icon {
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }
}

.ant-checkbox-group-item {
  margin: 8px !important;
}

.dark-green {
  color: $dark-green;
}
.green {
  color: $green;
}

.badge-autosave {
  background-color: #f1f9f7;
  color: #193933;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 2px;
}

.highlight {
  color: red !important;
  // background-color: red;
}

.custom-block-indent-a {
  margin-left: 10%;
}

.custom-block-indent-b {
  margin-left: 20%;
}

.custom-block-indent-c {
  margin-left: 30%;
}

.ant-upload-list-item-card-actions {
  .anticon-delete {
    display: none;
  }
}
