.page-event-log {
  // height: 100vh;
  // overflow-y: scroll;
  // overflow-x: hidden;
  // box-sizing: border-box;
  padding: 32px 24px;

  .timeline-container {
    .ant-timeline-item {
      padding-bottom: 32px;
    }
    .ant-collapse {
      background-color: #fff;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0px;
      color: #83c8bc;
    }
    .ant-collapse-item {
      border: none;
    }
    .timeline-date-row {
      // display: flex;
      // justify-content: center;
      // margin-top: -12px;
      margin-left: 24px;
      margin-bottom: 20px;
    }
    .timeline-date {
      padding: 4px 8px;
      background-color: #fafafa;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 2px;
    }
    .secondary-timeline-item .ant-timeline-item-tail {
      position: absolute;
      top: 10px;
      left: 4px;
      height: calc(100% - 10px);
      border-left: 2px dotted #f0f0f0;
    }
    .timeline-item {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      .timeline-item-date {
        width: 70px;
        font-size: 12px;
        line-height: 20px;
        color: #8c8c8c;
      }
      .timeline-item-text {
        .text_1 {
          font-size: 14px;
          line-height: 22px;
          color: #262626;
          width: 400px;
        }
        .text_2 {
          font-style: italic;
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
          color: #8c8c8c;
        }
        .text_3 {
          font-size: 12px;
          line-height: 20px;
          color: #8c8c8c;
          .toggle-btn {
            font-size: 12px;
            line-height: 20px;
            color: #83c8bc;
            cursor: pointer;
          }
        }
      }
    }

    ul.ant-timeline {
      .timeline-item:last-child {
        .ant-timeline-item-tail {
          display: none;
        }
      }
    }
  }

  .calendar-container {
    .ant-picker-calendar-header {
      padding-left: 4px;
      padding-right: 8px;
    }
    .ant-picker-calendar-mode-switch {
      display: none;
    }
    .events {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        text-align: right;
      }
    }
    .events .ant-badge-status {
      width: 100%;
      overflow: hidden;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .notes-month {
      font-size: 28px;
      text-align: center;
    }
    .notes-month section {
      font-size: 28px;
    }
    .ant-picker-calendar-date-content {
      height: 24px;
    }
  }
  .list-container {
    .text_1 {
      font-size: 14px;
      line-height: 22px;
      color: #262626;
    }
    .text_2 {
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #8c8c8c;
    }
    .text_3 {
      font-size: 12px;
      line-height: 20px;
      color: #8c8c8c;
    }
    .border-orange {
      border-left: 4px solid #ffc53d;
    }
    .border-green {
      border-left: 4px solid #83c8bc;
    }
    .border-blue {
      border-left: 4px solid #2f54eb;
    }
    .list {
      margin-top: 16px;
      background: #fff;
    }
    .ant-list-item {
      padding-left: 20px;
    }
  }

  // TODO
  .ant-card-body {
    height: 88vh;
    padding-left: 48px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
