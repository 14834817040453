.spinner {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .spinner-icon {
    width: 50%;
    max-width: 300px;
    height: 50%;
    max-height: 300px;
    animation: loadingAnimation 2s ease-in-out infinite;
  }
}
@keyframes loadingAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
  }
}
