.page-documents {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 32px 24px;

  .document-box {
    position: relative;
    width: 256px;
    height: 55px;
    margin-right: 16px;
    margin-bottom: 12px;
    border: 1px solid #eee;
    box-sizing: border-box;

    .ant-checkbox {
      position: absolute;
      right: 0.8em;
      top: 0.6em;
    }
    .document-box-inner {
      margin-top: 6px;
    }
    .document-icon {
      margin-top: 6px;
      margin-right: 16px;
    }
    .document-text {
      .text_1 {
        font-size: 14px;
        line-height: 22px;
        color: #000000;
      }
      .text_2 {
        font-size: 12px;
        line-height: 20px;
        color: #8c8c8c;
      }
    }
  }
}
