@import "../../../styles/abstracts/variables";
@import "../../../styles/colors.scss";

.fullscreen-spinner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $zindex-popover;
}
