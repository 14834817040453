@import "../../styles/colors.scss";

.page-summary {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 32px 24px;
  .partA {
    .personal {
      .header {
        .text_title {
          font-weight: 600;
          font-size: 16px;
          color: #262626;
        }
        .icon {
          float: right;
          opacity: 1;
          &:hover {
            cursor: pointer;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .calculations div span:nth-child(1) {
    font-size: 14px;
    line-height: 24px;
    color: #262626;
  }
  .calculations div span:nth-child(2) {
    float: right;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #262626;
  }
}

.page-night {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 32px 24px;

  .text-1 {
    font-size: 14px;
    line-height: 28px;
    color: #262626;
  }
  .text-2 {
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color: #262626;
  }

  .two-columns-card {
    div span:nth-child(1) {
      font-size: 14px;
      line-height: 28px;
      color: #262626;
    }
    div span:nth-child(2) {
      float: right;
      font-weight: 300;
      font-size: 14px;
      line-height: 28px;
      color: #262626;
    }
  }
}

.green {
  color: $green;
}
