.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light
  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed {
  li {
    margin: 8px auto;
  }
}

.sider-trigger {
  position: fixed;
  bottom: 88px;
  left: 32px;
  cursor: pointer;
}
.site-layout-background {
  .logo {
    padding: 18px 24px;
    background: #00474f;
    box-sizing: border-box;
    img {
      height: 28px;
      width: 24px;
    }
  }
}
.ant-menu-item .ant-menu-submenu {
  margin: 32px auto;
}

.ant-card-head-wrapper {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  .ant-card-head-title {
    padding-top: 16px;
    padding-bottom: 12px;
  }
}

.ant-layout {
  .ant-layout-content {
    height: 100vh;
    padding: 0px !important;
    margin: 0px !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

// .ant-form-item-label > label {
//   height: auto;
// }
