$brand-primary: rgb(88, 199, 180);
$brand-secondary: #566463;

$blue: #2ea1f8;
$white: #ffffff;
$black: #000000;
$dark: #566463;

$cyan: #83c8bc;
$cyan-dark: #566663;
$cyan-medium: #758985;
$cyan-light: #cae7e2;

// $gray: #7f8fa4;
$gray-light: #dfe3eb;
// $gray-lighter: #f8fafc;

$subtle-grey: #f5f8fa;

$red: #d25459;

$green: #83c8bc;
$orange: #ffc53d;
$blue: #2f54eb;

$dark-green: "#193933";

$background-color: #eff3f6;

$suspended: $orange;

$gray: #8c8c8c;
$gray-lighter: #d9d9d9;
